<template>
  <v-app>

    <!--------------------------------- Toolbar -------------------------------- -->
    <v-app-bar app dense elevate-on-scroll clipped-right elevation="3">
      <v-img src="@/assets/logo.svg" max-width="26" aspect-ratio="1"></v-img>
      <v-spacer class="d-sm-inline d-md-none"></v-spacer>
      <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu offset-y max-width="250">
          <template v-slot:activator="{on}">
            <v-btn v-on="on" text>
              <LoginAvatar :user="loginUserInfo" :size="34" :tooltip="false" />
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="logout">
              <v-list-item-icon><v-icon size="20">mdi-logout-variant</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>ログアウト</v-list-item-title></v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <!---------------------------------- Main ---------------------------------- -->
    <v-main>
      <FlashMessage />
      <router-view />
    </v-main>

    <!--------------------------------- Footer --------------------------------- -->
    <!--
    <v-footer app inset absolute>
      <small>&copy; 2013-2022 <a href="https://phonogram.co.jp" target="_blank">Phonogram</a> Inc.</small>
    </v-footer>
    -->
  </v-app>
</template>

<script>
import FlashMessage from '@/components/FlashMessage.vue'
import LoginAvatar from '@/components/LoginAvatar.vue'

export default {
  name: 'Guest',
  components: {
    FlashMessage,
    LoginAvatar
  },
  data: function () {
    return {
    }
  },
  created: function () {
  },
  mounted () {
    document.body.id = 'guest'
  },
  computed: {
    loginUserInfo: function () {
      return this.$store.getters.loginUserInfo
    },
    pageTitle: function () {
      return this.$route.meta.title
    }
  },
  watch: {
  },
  methods: {
    logout () {
      this.userSignOut()
    }
  }
}
</script>
